.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 110px;
}

.tablica-ime:hover {
    cursor: pointer;
    background: #4386cb;
}