.fixture-row:hover {
    background-color: #b5bdc2;
    cursor: pointer;
}

.comments-container {
    margin-top: 40px;
    justify-content: flex-start;
    width: 70vw;
}

.form-container {
    margin-top: 40px;
    justify-content: flex-end;
    align-items: flex-end;
    width: 70vw;
}

#text {
    width: 100%;
    height: 50px;
}

#dialog {
    width: 100%;
}

.inp {
    width: 8px;
    margin-left: 5px;
    margin-bottom: 5px;
}